import React from 'react'
import { request } from '@recmed/services/http'

const useFetch = ({ endpoint, method = 'GET' }) => {
  const [loading, setLoading] = React.useState(true)
  const called = React.useRef(null)
  const error = React.useRef(null)
  const data = React.useRef(null)

  const fetch = React.useCallback(
    async (body = {}) => {
      try {
        called.current = true
        setLoading(true)
        const response = await request(endpoint, method, body)
        data.current = response
        return response
      } catch (e) {
        error.current = e
        throw e
      } finally {
        setLoading(false)
      }
    },
    [endpoint, method],
  )

  return {
    fetch,
    error: error.current,
    loading,
    called: called.current,
    data: data.current,
  }
}

export { useFetch }
