import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import { useTranslation } from '@recmed/components/hooks/useTranslation'
import { useAppContext } from '@recmed/components/hooks/useAppContext'
import { Rating } from '../Rating'

const BookingSummary = ({
  title = 'booking-summary-title',
  doctorId,
  name,
  photo,
  rating,
  location,
  date: selected,
}) => {
  const { getPath } = useAppContext()
  const { translate } = useTranslation()

  const date = React.useMemo(() => moment(selected), [selected])

  return (
    <Card className="booking-card">
      <Card.Header>
        <Card.Title>{translate(title)}</Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="booking-doc-info">
          <Link to={getPath(`/profile/${doctorId}`)} className="booking-doc-img">
            <img src={photo} alt={name} />
          </Link>
          <div className="booking-info">
            <h4>
              <Link to={getPath(`/profile/${doctorId}`)}>{name}</Link>
            </h4>
            <Rating rating={rating} />
            <div className="clinic-details">
              <p className="doc-location">
                <i className="fas fa-map-marker-alt" /> {location}
              </p>
            </div>
          </div>
        </div>
        <div className="booking-summary">
          <div className="booking-item-wrap">
            <ul className="booking-date">
              <li>
                {translate('date-text')} <span>{date.format('DD MMMM YYYY')}</span>
              </li>
              <li>
                {translate('time-text')} <span>{date.format('hh:mm A')}</span>
              </li>
            </ul>
            <ul className="booking-fee">
              <li>
                {translate('service-selected-text')} <span>servicio-1</span>
              </li>
              <li>
                {translate('service-fee-text')} <span>$0</span>
              </li>
              <li>
                {translate('booking-fee-text')} <span>$0</span>
              </li>
              <li>
                {translate('video-call-fee-text')} <span>$0</span>
              </li>
            </ul>
            <div className="booking-total">
              <ul className="booking-total-list">
                <li>
                  <span>{translate('total-amount-text')}</span>
                  <span className="total-cost">$0</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

BookingSummary.propTypes = {
  title: PropTypes.string,
  doctorId: PropTypes.string,
  name: PropTypes.string,
  photo: PropTypes.string,
  location: PropTypes.string,
  date: PropTypes.string,
  rating: PropTypes.number,
}

export { BookingSummary }
