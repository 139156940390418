import React from 'react'
import PropTypes from 'prop-types'

import { SearchItem } from '@recmed/components/molecules/SearchItem'
import { doctorSearch } from '@recmed/shared/prop-types/search'

const SearchList = ({ items = [] }) => {
  return (
    <>
      {items.map(({ id, name, photo, locations, description, specialities, stars }) => (
        <SearchItem
          id={id}
          key={id}
          photo={photo}
          name={name}
          locations={locations}
          description={description}
          specialities={specialities}
          stars={stars}
        />
      ))}
    </>
  )
}

SearchList.propTypes = {
  items: PropTypes.arrayOf(doctorSearch),
}

export { SearchList }
