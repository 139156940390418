import PropTypes from 'prop-types'

const locationShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
})

const doctorSearch = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  locations: PropTypes.arrayOf(locationShape),
  specialities: PropTypes.arrayOf(PropTypes.string),
  stars: PropTypes.number,
  photo: PropTypes.string,
}

export { doctorSearch, locationShape }
