import React from 'react'
import PropTypes from 'prop-types'

const NavbarMenu = ({ children }) => {
  return <ul className="main-nav">{children}</ul>
}

NavbarMenu.propTypes = {
  children: PropTypes.node,
}

export { NavbarMenu }
