import React from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'

const Input = ({ className, placeholder, value, onChange }) => {
  return (
    <input
      type="text"
      className={cn('form-control', className)}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  )
}

Input.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export { Input }
