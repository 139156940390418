import React from 'react'
import PropTypes from 'prop-types'

const BreadcrumbBar = ({ children, matches }) => {
  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-8 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                {children}
              </nav>
              {matches && <h2 className="breadcrumb-title">{matches}</h2>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

BreadcrumbBar.propTypes = {
  children: PropTypes.node,
  matches: PropTypes.string,
}

export { BreadcrumbBar }
