import React from 'react'

import cn from 'classnames'
import { RichText } from 'prismic-reactjs'
import { HomeSplash } from '@recmed/components/organisisms/HomeSplash'
import { HomeFeature } from '@recmed/components/organisisms/HomeFeature'
import { HomeSpeciality } from '@recmed/components/organisisms/HomeSpeciality'
import { HomePricing } from '@recmed/components/organisisms/HomePricing'
import { Loading } from '@recmed/components/atoms/Loading'

import { useFindByDocumentId } from '@recmed/components/hooks/useFindByDocumentId'
import { ChoosePlanModal } from '@recmed/components/organisisms/ChoosePlanModal'
import classes from './styles.module.scss'

const Landing = () => {
  const [show, setShow] = React.useState(false)
  const { loading, result } = useFindByDocumentId({ documentId: 'XwoO_BAAACEAgytG' })

  if (loading) return <Loading className={classes.root} />
  if (!result) return <div className={classes.root}>error</div>

  const {
    data: {
      background_image: backgroundImage,
      home_title: title,
      home_subtitle: subtitle,
      feature_title: featureTitle,
      feature_description: featureSubtitle,
      feature_group: featureItems,
      speciality_title: specialityTitle,
      speciality_description: specialitySubtitle,
      speciality_group: specialityItems,
      pricing_title: pricingTitle,
      pricing_subtitle: pricingSubtitle,
      image_description_section,
      body = [],
    },
  } = result

  console.info(image_description_section)

  const pricingItems = body.filter(({ slice_type }) => slice_type === 'pricing_slice')

  return (
    <>
      <HomeSplash backgroundImage={backgroundImage} title={title} subtitle={subtitle} />
      <HomeFeature
        title={featureTitle}
        subtitle={featureSubtitle}
        items={featureItems}
        images={image_description_section}
      />
      <HomeSpeciality
        title={specialityTitle}
        subtitle={specialitySubtitle}
        items={specialityItems}
      />

      {image_description_section.map(({ main_image, title, subtitle }, key) => (
        <section
          className={cn('section', {
            'section-features': key % 2 === 0,
            'section-doctor': key % 2 !== 0,
          })}
          key={key}
        >
          <div className="container-fluid">
            <div className="sect">
              <div className="section-header text-center">
                <RichText render={title} />
                <p className="sub-title">
                  <RichText render={subtitle} />
                </p>
              </div>
              <div className="row justify-content-center">
                <img className={classes.imageSection} src={main_image?.url} alt={main_image?.alt} />
              </div>
            </div>
          </div>
        </section>
      ))}
      <HomePricing
        subtitle={pricingSubtitle}
        title={pricingTitle}
        items={pricingItems}
        showModal={() => setShow(true)}
      />
      {show && <ChoosePlanModal plans={pricingItems} show={show} onClose={() => setShow(false)} />}
    </>
  )
}

export { Landing }
