import React from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'
import classes from './styles.module.scss'

const Header = ({ children }) => {
  return (
    <header className={cn('header', classes.header)}>
      <nav className="navbar navbar-expand-lg header-nav">{children}</nav>
    </header>
  )
}

Header.propTypes = {
  children: PropTypes.node,
}

export { Header }
