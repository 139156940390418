// const BASE_URL = 'http://3.211.42.58:9001/api'
const { REACT_APP_API_ENVIRONMENT } = process.env
const BASE_URL = `/api-${REACT_APP_API_ENVIRONMENT}`

const request = async (path, method, body) => {
  const endpoint = `${BASE_URL}${path}`
  const data = await fetch(endpoint, {
    body: method !== 'GET' ? JSON.stringify(body) : undefined,
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (data.status >= 400) {
    throw await data.json()
  }

  return data.json()
}

const post = (path, body) => request(path, 'POST', body)
const get = (path, body) => request(path, 'GET')

export { request, post, get, BASE_URL }
