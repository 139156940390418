import React from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'
import { useTranslation } from '@recmed/components/hooks/useTranslation'

const Loading = ({ className, message = 'loading-text', style }) => {
  const { translate } = useTranslation()
  return (
    <div className={cn('column align-items-center', className)} style={{ style }}>
      <div className="spinner-border text-primary" role="status" />
      <span>{translate(message)}</span>
    </div>
  )
}

Loading.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  style: PropTypes.object,
}

export { Loading }
