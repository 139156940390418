import React from 'react'
import PropTypes from 'prop-types'

import { Star } from '@recmed/components/atoms/Star'

const Rating = ({ rating = 0, max = 5 }) => {
  return (
    <div className="rating">
      {Array(rating)
        .fill(null)
        .map((n, key) => (
          <Star key={key} filled />
        ))}
      {Array(max - rating)
        .fill(null)
        .map((n, key) => (
          <Star key={key} />
        ))}
    </div>
  )
}

Rating.propTypes = {
  rating: PropTypes.number,
  max: PropTypes.number,
}

export { Rating }
