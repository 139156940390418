import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import Modal from 'react-bootstrap/Modal'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import { locationShape } from '@recmed/shared/prop-types/search'
import { useAppContext } from '@recmed/components/hooks/useAppContext'
import { useTranslation } from '@recmed/components/hooks/useTranslation'

const OrganizationsModal = ({ show, onClose, name, locations, id }) => {
  const { translate } = useTranslation()
  const { getPath } = useAppContext()
  const [organization, setOrganization] = React.useState()
  const history = useHistory()
  const handleSubmit = () => {
    history.push(getPath(`/booking/${id}/${organization}`))
  }
  return (
    <>
      <Modal show={show} backdrop="static" onHide={onClose} keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {name}
            <Modal.Title as="h5">{translate('select-consultation-text')}</Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup activeKey={organization}>
            {locations.map(({ id, name, address }) => (
              <ListGroup.Item
                key={id}
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  if (id === organization) {
                    setOrganization('')
                    return
                  }
                  setOrganization(id)
                }}
                href={id}
                action
              >
                <p>
                  <h4>{name}</h4>
                </p>
                <p>{address}</p>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          {organization && (
            <Button onClick={handleSubmit}>{translate('continue-button-text')}</Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

OrganizationsModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  locations: PropTypes.arrayOf(locationShape),
}

export { OrganizationsModal }
