import React from 'react'

import qs from 'querystring'
import { useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import moment from 'moment-timezone'
import { useFetch } from '@recmed/components/hooks/useFetch'
import { post } from '@recmed/services/http'
import { useAppContext } from '@recmed/components/hooks/useAppContext'
import { useTranslation } from '@recmed/components/hooks/useTranslation'

const ValidationSchema = yup.object().shape({
  name: yup.string().required(),
  lastName: yup.string().required(),
  documentType: yup.string().required(),
  document: yup.string().required(),
  gender: yup.string().required(),
  dateOfBirth: yup.date().required(),
  phone: yup.string().required(),
  insurance: yup.string().required(),
  affiliationNo: yup.mixed().when('insurance', {
    is: (insurance) => ['', null, undefined, 'PP', 'true'].includes(insurance),
    then: yup.string().notRequired().nullable(),
    otherwise: yup.string().required(),
  }),
  email: yup.string().email().required(),
  address: yup.string(),
})

const useCheckout = () => {
  const [load, setLoad] = React.useState(false)
  const { displayToast, getPath } = useAppContext()
  const { translate } = useTranslation()
  const history = useHistory()
  const methods = useForm({
    resolver: yupResolver(ValidationSchema),
  })
  const { doctorId, organizationId, date: selected } = useParams()
  const date = React.useMemo(() => moment(selected), [selected])

  const { loading, data, fetch } = useFetch({ method: 'POST', endpoint: '/doctors/slots' })

  const submit = methods.handleSubmit(async (form) => {
    try {
      setLoad(true)
      await post('/submit', {
        doctorId,
        organizationId,
        appointment: { date: selected },
        form: { ...form, dateOfBirth: moment(form.dateOfBirth).format('YYYY-MM-DD') },
      })
      history.push({
        pathname: getPath(`/booking/success/${doctorId}/${organizationId}/${selected}`),
        search: qs.encode({ name: `${form.name} ${form.lastName}` }),
      })
    } catch (e) {
      displayToast({ content: translate(e.message), type: 'error' })
    } finally {
      setLoad(false)
    }
  })

  React.useEffect(() => {
    fetch({
      from: date.format(),
      to: date.format(),
      doctorId,
      organizationId,
    })
  }, [date, doctorId, organizationId, fetch])

  return {
    doctorId,
    disabled: document.length !== 11,
    date: selected,
    methods,
    data,
    loading,
    submit,
    load,
  }
}

export { useCheckout }
