import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import cn from 'classnames'
import ReactDatePicker from 'react-datepicker'
import Form from 'react-bootstrap/Form'
import { useFormContext, Controller } from 'react-hook-form'
import { IMaskInput } from 'react-imask'

import classes from './styles.module.scss'

const DatePicker = ({ disabled, name, placeholder = 'DD/MM/YYYY', label }) => {
  const { control, errors } = useFormContext()

  const error = get(errors, name)
  return (
    <>
      <Form.Group className={cn('form-group card-label', { [classes.disabled]: disabled })}>
        <Form.Label>{disabled ? '' : label}</Form.Label>
        <Controller
          control={control}
          name={name}
          render={({ onChange, value, onBlur }) => (
            <ReactDatePicker
              selected={value}
              dateFormat="dd/MM/yyyy"
              onBlur={onBlur}
              onChange={onChange}
              disabled={disabled}
              customInput={<IMaskInput mask="00/00/0000" />}
              placeholderText={disabled ? label : placeholder}
              className="form-control"
            />
          )}
        />
      <Form.Control.Feedback type="invalid" className={cn({ [classes.visible]: error?.message })}>
        {error?.message}
      </Form.Control.Feedback>
      </Form.Group>
    </>
  )
}
DatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}

export { DatePicker }
