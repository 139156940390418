import React from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'

const HeaderText = ({ className, data = [] }) => {
  if (!Array.isArray(data) || !data.length) return null
  const [{ text, type }] = data
  const Component = type.replace('eading', '')
  return <Component className={cn(type, className)}>{text}</Component>
}

HeaderText.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
}

export { HeaderText }
