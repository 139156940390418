import React from 'react'
import PropTypes from 'prop-types'

import { RichText } from 'prismic-reactjs'
import { HeaderText } from '@recmed/components/atoms/HeaderText'
import { PlanItem } from '@recmed/components/molecules/PlanItem'

const HomePricing = ({ title, subtitle, items = [], showModal }) => {
  return (
    <section className="section section-doctor">
      <div className="container-fluid" id="pricing-section">
        <div className="section-header text-center">
          <HeaderText data={title} />
          <p className="sub-title">{RichText.asText(subtitle)}</p>
        </div>

        <div className="pricing pricing-palden" >
          {items.map(
            (
              {
                primary: {
                  pricing_title,
                  pricing_description,
                  monthly_price,
                  anual_price,
                  principal,
                },
                items,
              },
              index,
            ) => {
              return (
                <PlanItem
                  showModal={showModal}
                  key={`plan-item-${index}`}
                  principal={principal}
                  subtitle={pricing_description}
                  title={pricing_title}
                  items={items}
                  anual={anual_price}
                  monthly={monthly_price}
                />
              )
            },
          )}
        </div>
      </div>
    </section>
  )
}

HomePricing.propTypes = {
  title: PropTypes.array,
  subtitle: PropTypes.array,
  items: PropTypes.array,
}

export { HomePricing }
