import React from 'react'

import { Link } from 'react-router-dom'
import { useTranslation } from '@recmed/components/hooks/useTranslation'
import { useAppContext } from '@recmed/components/hooks/useAppContext'
import { BreadcrumbBar } from '@recmed/components/atoms/BreadcrumbBar'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { RichText } from 'prismic-reactjs'
import { useFindByDocumentId } from '@recmed/components/hooks/useFindByDocumentId'
import { Loading } from '@recmed/components/atoms/Loading'

const TermsAndConditions = () => {
  const { getPath } = useAppContext()
  const { translate } = useTranslation()

  const { loading, result } = useFindByDocumentId({ documentId: 'XyGrIBEAACMArGGB' })

  console.info(result)

  return (
    <>
      <BreadcrumbBar>
        <Breadcrumb>
          <Link component={Breadcrumb.Item} to={getPath('/')}>
            {translate('home-text')}
          </Link>
          <Link component={Breadcrumb.Item} to={getPath('/terms')}>
            {translate('terms-and-conditions-text')}
          </Link>
        </Breadcrumb>
        <h2 className="breadcrumb-title">{translate('terms-and-conditions-text')}</h2>
      </BreadcrumbBar>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="terms-text">
                {loading ? (
                  <Loading />
                ) : (
                  <RichText render={result?.data?.terms_and_conditions || []} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { TermsAndConditions }
