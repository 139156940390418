import React from 'react'

import qs from 'querystring'
import moment from 'moment-timezone'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import { BreadcrumbBar } from '@recmed/components/atoms/BreadcrumbBar'
import { SearchItem } from '@recmed/components/molecules/SearchItem'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { useAppContext } from '@recmed/components/hooks/useAppContext'
import { useFetch } from '@recmed/components/hooks/useFetch'
import { Loading } from '@recmed/components/atoms/Loading'

import { DateScheduleHeader } from '@recmed/components/molecules/DateScheduleHeader'
import { DateScheduleContent } from '@recmed/components/molecules/DateScheduleContent'
import { useTranslation } from '@recmed/components/hooks/useTranslation'
import classes from './styles.module.scss'

const Booking = React.memo(() => {
  const { translate } = useTranslation()
  const today = React.useMemo(() => moment().startOf('day'), [])
  const [selected, setSelected] = React.useState('')
  const { getPath } = useAppContext()
  const { doctorId, organizationId } = useParams()
  const { search } = useLocation()
  const history = useHistory()
  const { from } = React.useMemo(() => {
    const { from = today.clone().format('YYYY-MM-DD') } = qs.decode(search.substr(1))
    return { from: moment(from, 'YYYY-MM-DD') }
  }, [search, today])

  const { loading, data, fetch, called } = useFetch({ method: 'POST', endpoint: '/doctors/slots' })

  const onNextWeekPressed = React.useCallback(
    (event) => {
      event.stopPropagation()
      event.preventDefault()
      setSelected('')
      history.push({
        search: qs.encode({
          from: from.clone().add(7, 'days').format('YYYY-MM-DD'),
        }),
      })
    },
    [from, history],
  )

  const onPreviousWeekPressed = React.useCallback(
    (event) => {
      event.stopPropagation()
      event.preventDefault()
      setSelected('')
      history.push({
        search: qs.encode({
          from: from.clone().add(-7, 'days').format('YYYY-MM-DD'),
        }),
      })
    },
    [from, history],
  )

  React.useEffect(() => {
    fetch({
      from: from.format(),
      to: from.clone().add(7, 'days').format(),
      doctorId,
      organizationId,
    })
  }, [from, doctorId, organizationId, fetch])

  return (
    <>
      <BreadcrumbBar>
        <Breadcrumb>
          <Link component={Breadcrumb.Item} to={getPath('/doctors')}>
            {translate('doctor-list')}
          </Link>
          <Link component={Breadcrumb.Item} to={getPath('/search')}>
            {translate('doctor-search')}
          </Link>
          {called && data && (
            <Link
              component={Breadcrumb.Item}
              to={getPath(`/booking/${doctorId}/${organizationId}`)}
            >
              {data?.name}
            </Link>
          )}
        </Breadcrumb>
      </BreadcrumbBar>
      <div className="content">
        <div className="container">
          <div className="row">
            {loading && !called && <Loading className={classes.root} />}
            {called && (
              <>
                <div className="col-12">
                  <SearchItem
                    hideButtons
                    id={data?.id}
                    name={data?.name}
                    photo={data?.photo}
                    stars={data?.stars}
                    description={data?.description}
                    locations={[data?.location]}
                  />
                  {/* DATE */}
                  <div className="row">
                    <div className="col-12 col-sm-4 col-md-6">
                      <h4 className="mb-1">{today.format('DD MMMM YYYY')}</h4>
                      <p className="text-muted">{today.format('dddd')}</p>
                    </div>
                  </div>
                  {/* SCHEDULE */}
                  <div className="card booking-schedule schedule-widget">
                    <DateScheduleHeader
                      from={from}
                      onNextWeekPressed={onNextWeekPressed}
                      onPreviousWeekPressed={onPreviousWeekPressed}
                      today={today}
                    />
                    {loading && <Loading className={classes.loading} />}
                    {!loading && (
                      <DateScheduleContent
                        slots={data?.location?.slots}
                        from={from}
                        onSelect={setSelected}
                        selected={selected}
                      />
                    )}
                  </div>
                  {selected && (
                    <div className="submit-section proceed-btn text-right">
                      <Link
                        className="btn btn-primary submit-btn"
                        to={getPath(`/booking/${doctorId}/${organizationId}/${selected}`)}
                      >
                        {translate('continue-button-text')}
                      </Link>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
})

export { Booking }
