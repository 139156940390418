import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { useTranslation } from '@recmed/components/hooks/useTranslation'
import { RichText } from 'prismic-reactjs'

const { REACT_APP_LOGIN_HREF } = process.env
const NavbarWrapper = ({ children, phone = [] }) => {
  const { translate } = useTranslation()
  return (
    <>
      <div className="main-menu-wrapper">
        <div className="menu-header">
          <Link to="/home" className="menu-logo">
            <img src="img/logo.png" className="img-fluid" alt="Logo" />
          </Link>
          <Link id="menu_close" className="menu-close" to="/home">
            <i className="fas fa-times" />
          </Link>
        </div>
        {children}
      </div>
      <ul className="nav header-navbar-rht">
        <li className="nav-item contact-item">
          <div className="header-contact-img">
            <i className="far fa-hospital" />
          </div>
          <div className="header-contact-detail">
            <p className="contact-header">{translate('contact-button')}</p>
            <a href={`tel:${RichText.asText(phone)}`} className="contact-info-header">
              {RichText.asText(phone)}
            </a>
          </div>
        </li>
        <li className="nav-item">
          <a
            className="nav-link header-login"
            target="_blank"
            rel="noopener noreferrer"
            href={REACT_APP_LOGIN_HREF}
          >
            {translate('sign-in-button')}
          </a>
        </li>
      </ul>
    </>
  )
}

NavbarWrapper.propTypes = {
  children: PropTypes.element,
  phone: PropTypes.array,
}

export { NavbarWrapper }
