import * as yup from 'yup'
import i18n from 'i18next'

yup.setLocale({
  string: {
    email: () => i18n.t('is-not-valid-email-field-text'),
  },
  mixed: {
    required: () => {
      return i18n.t('is-required-field-text')
    },
  },
})

export { yup }
