import React from 'react'
import PropTypes from 'prop-types'

import qs from 'querystring'
import { useHistory, useLocation } from 'react-router-dom'
import { Input } from '@recmed/components/atoms/Input'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { useAppContext } from '@recmed/components/hooks/useAppContext'
import { useTranslation } from '@recmed/components/hooks/useTranslation'

const DoctorFilter = ({ title = 'search-filters' }) => {
  const { translate } = useTranslation()
  const initalized = React.useRef(false)
  const { getPath } = useAppContext()
  const history = useHistory()
  const location = useLocation()
  const [value, setValue] = React.useState('')

  const onSubmit = React.useCallback(
    (val = value) => {
      history.push({
        pathname: getPath('/search'),
        search: qs.encode({ name: val }),
      })
      if (val !== value) setValue(val)
    },
    [value, history, getPath],
  )

  React.useEffect(() => {
    if (initalized.current) return
    initalized.current = true
    const { name = '' } = qs.decode(location.search.substr(1))
    if (name === value) return
    setValue(name)
  }, [value, location])

  return (
    <>
      <Card className="search-filter">
        <Card.Header>
          <Card.Title className="mb-0">{translate(title)}</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="filter-widget">
            <Input
              placeholder={translate('search')}
              name="name"
              value={value}
              onChange={({ target: { value } }) => {
                setValue(value)
              }}
            />
          </div>
          <div className="btn-search">
            <Button className="btn btn-block" onClick={() => onSubmit()}>
              {translate('search-button-text')}
            </Button>
          </div>
          {value && (
            <div className="other-info">
              <Button
                className="btn-block"
                variant="outline-warning"
                onClick={() => {
                  onSubmit('')
                }}
              >
                {translate('clear-button-text')}
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

DoctorFilter.propTypes = {
  title: PropTypes.string,
}

export { DoctorFilter }
