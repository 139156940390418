import React from 'react'

import { yup } from '@recmed/services/yup-locales'
import qs from 'querystring'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { useTranslation } from '@recmed/components/hooks/useTranslation'
import { RichText } from 'prismic-reactjs'
import { yupResolver } from '@hookform/resolvers'
import { useAppContext } from '@recmed/components/hooks/useAppContext'
import { useFetch } from '@recmed/components/hooks/useFetch'

const ValidationSchema = yup.object().shape({
  planType: yup.string().required(),
  planFee: yup.string().required(),
  name: yup.string().required(),
  lastName: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().email().required(),
  commercialType: yup.string().required(),
})

const usePlanModal = ({ plans = [], onClose }) => {
  const { displayToast } = useAppContext()
  const { search } = useLocation()
  const { planType: pt = '' } = qs.decode(search.substr(1))
  const methods = useForm({
    defaultValues: { planType: pt },
    resolver: yupResolver(ValidationSchema),
  })
  const { translate } = useTranslation()
  const { loading, called, fetch } = useFetch({ method: 'POST', endpoint: '/subscribe' })

  const { watch } = methods
  const planType = watch('planType')
  const commercialType = watch('commercialType')
  const items = React.useMemo(() => {
    if (!plans) return []
    return plans.map(({ primary: { pricing_title } }) => ({
      id: RichText.asText(pricing_title),
      name: RichText.asText(pricing_title),
    }))
  }, [plans])

  const prices = React.useMemo(() => {
    if (!planType) return []
    const plan = plans.find(
      ({ primary: { pricing_title } }) => RichText.asText(pricing_title) === planType,
    )
    if (!plan) return []
    const {
      primary: { monthly_price, anual_price },
    } = plan
    return [
      {
        id: 'ANNUAL',
        name: `USD$ ${RichText.asText(anual_price)} /${translate('annual-label-text')}`,
      },
      {
        id: 'MONTHLY',
        name: `USD$ ${RichText.asText(monthly_price)} /${translate('monthly-label-text')}`,
      },
    ]
  }, [planType, plans, translate])

  const handleSubmit = methods.handleSubmit(async (form) => {
    try {
      await fetch(form)
      displayToast({ content: translate('subscribe-message-text'), type: 'sucess' })
      onClose()
    } catch (e) {
      console.info(e)
      displayToast({ content: translate(e.message), type: 'error' })
    }
  })

  return {
    commercialType,
    items,
    prices,
    translate,
    planType,
    methods,
    handleSubmit,
    loading: called && loading,
  }
}

export { usePlanModal }
