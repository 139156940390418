import React from 'react'
import { AppContext } from '@recmed/components/context/AppContext'

const useAppContext = () => {
  const { language, displayToast, general } = React.useContext(AppContext)

  const getPath = (path) => `/${language}${path}`

  return { language, getPath, displayToast, general }
}

export { useAppContext }
