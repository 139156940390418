import React from 'react'
import PropTypes from 'prop-types'

const SearchContent = ({ children }) => {
  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">{children}</div>
      </div>
    </div>
  )
}

SearchContent.propTypes = {
  children: PropTypes.node,
}

export { SearchContent }
