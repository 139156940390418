import React from 'react'
import PropTypes from 'prop-types'

import { Link, useHistory } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import { useAppContext } from '@recmed/components/hooks/useAppContext'
import { doctorSearch } from '@recmed/shared/prop-types/search'
import { OrganizationsModal } from '@recmed/components/organisisms/OrganizationsModal'
import { useTranslation } from '@recmed/components/hooks/useTranslation'
import { Rating } from '../Rating'

const SearchItem = ({
  id,
  name,
  locations = [],
  description,
  specialities = [],
  stars = 0,
  photo = '',
  hideButtons,
}) => {
  const { translate } = useTranslation()
  const [show, setShow] = React.useState(false)
  const history = useHistory()
  const { getPath } = useAppContext()

  const handleBooking = () => {
    if (locations.length <= 1) {
      history.push(getPath(`/booking/${id}/${locations[0].id}`))
      return
    }
    setShow(true)
  }

  return (
    <>
      <OrganizationsModal
        show={show}
        id={id}
        name={name}
        locations={locations}
        onClose={() => setShow(false)}
      />
      <Card>
        <Card.Body>
          <div className="doctor-widget">
            <div className="doc-info-left">
              <div className="doctor-img">
                <Link to={getPath(`/profile/${id}`)}>
                  <img
                    src={photo || '/img/doctors/doctor-thumb-01.jpg'}
                    className="img-fluid"
                    alt={name}
                  />
                </Link>
              </div>
              <div className="doc-info-cont">
                <h4 className="doc-name">
                  <a href="doctor-profile.html">{name}</a>
                </h4>
                <p className="doc-speciality">{description}</p>
                {specialities.map((speciality) => (
                  <h5 className="doc-department" key={speciality}>
                    <img
                      src="/img/specialities/specialities-05.png"
                      className="img-fluid"
                      alt="Speciality"
                    />
                    {speciality}
                  </h5>
                ))}
                <Rating rating={stars} />
                <div className="clinic-details">
                  {locations.map(({ id, address }) => (
                    <a
                      key={id}
                      href={`https://www.google.com/maps/search/?api=1&query=${address}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <p className="doc-location">
                        <i className="fas fa-map-marker-alt" /> {address}
                      </p>
                    </a>
                  ))}
                </div>
              </div>
            </div>
            {!hideButtons && (
              <div className="doc-info-right">
                <div className="clini-infos">
                  <ul>
                    <li>
                      <i className="far fa-thumbs-up" /> {stars * 20}%
                    </li>
                  </ul>
                </div>
                <div className="clinic-booking">
                  <button
                    disabled
                    className="view-pro-btn"
                    // to={getPath(`/profile/${id}`)}
                  >
                    {translate('view-profile-text')}
                  </button>
                  <button className="apt-btn" onClick={handleBooking}>
                    {translate('book-appointment-text')}
                  </button>
                </div>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

SearchItem.propTypes = {
  ...doctorSearch,
  hideButtons: PropTypes.bool,
}

export { SearchItem }
