import React from 'react'
import PropTypes from 'prop-types'

const DateScheduleHeader = ({ today, from, onPreviousWeekPressed, onNextWeekPressed }) => {
  return (
    <div className="schedule-header">
      <div className="row">
        <div className="col-md-12">
          <div className="day-slot">
            <ul>
              <li className="left-arrow">
                {from.isAfter(today) && (
                  <a href="/" onClick={onPreviousWeekPressed}>
                    <i className="fa fa-chevron-left" />
                  </a>
                )}
              </li>
              {Array(7)
                .fill(null)
                .map((_, index) => {
                  const date = from.clone().add(index, 'days')
                  return (
                    <li key={date.format()}>
                      <span>{date.format('ddd')}</span>
                      <span className="slot-date">
                        {date.format('DD MMM ')}
                        <small className="slot-year">{date.format('YYYY')}</small>
                      </span>
                    </li>
                  )
                })}
              <li className="right-arrow">
                <a href="/" onClick={onNextWeekPressed}>
                  <i className="fa fa-chevron-right" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

DateScheduleHeader.propTypes = {
  today: PropTypes.object,
  from: PropTypes.object,
  onPreviousWeekPressed: PropTypes.func,
  onNextWeekPressed: PropTypes.func,
}

export { DateScheduleHeader }
