import React from 'react'

import qs from 'querystring'
import { useHistory } from 'react-router-dom'
import { DashboardSearch } from '@recmed/components/organisisms/DashboardSearch'
import { useFindByDocumentId } from '@recmed/components/hooks/useFindByDocumentId'
import { Loading } from '@recmed/components/atoms/Loading'

import { useAppContext } from '@recmed/components/hooks/useAppContext'
import classes from './styles.module.scss'

const Dashboard = () => {
  const [value, setValue] = React.useState('')
  const { getPath } = useAppContext()
  const history = useHistory()
  const { loading, result } = useFindByDocumentId({ documentId: 'XxV-jhEAACMAdkmG' })

  const onSubmit = React.useCallback(() => {
    if (!value) return
    history.push({
      pathname: getPath('/search'),
      search: qs.encode({ name: value }),
    })
  }, [value, history, getPath])

  if (loading) return <Loading className={classes.root} />
  if (!result) return <div className={classes.root}>error</div>

  const {
    data: { search_doctor_background, search_doctor_title, search_doctor_description },
  } = result

  return (
    <>
      <DashboardSearch
        value={value}
        onChange={setValue}
        onSubmit={onSubmit}
        image={search_doctor_background}
        subtitle={search_doctor_description}
        title={search_doctor_title}
      />
    </>
  )
}

export { Dashboard }
