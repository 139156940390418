import React from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'

import { RichText } from 'prismic-reactjs'
import { useTranslation } from '@recmed/components/hooks/useTranslation'
import { useAppContext } from '@recmed/components/hooks/useAppContext'

const PlanItem = ({ title, anual, monthly, items = [], principal }) => {
  const { general } = useAppContext()
  // const history = useHistory()
  const { translate } = useTranslation()
  return (
    <div className={cn('pricing-item', { 'pricing__item--featured': principal })}>
      <div className="pricing-deco">
        <svg
          className="pricing-deco-img"
          enableBackground="new 0 0 300 100"
          height="100px"
          id="Layer_1"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 300 100"
          width="300px"
          x="0px"
          xmlns="http://www.w3.org/2000/svg"
          y="0px"
        >
          <path
            className="deco-layer deco-layer--1"
            d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
            fill="#FFFFFF"
            opacity="0.6"
          />
          <path
            className="deco-layer deco-layer--2"
            d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
            fill="#FFFFFF"
            opacity="0.6"
          />
          <path
            className="deco-layer deco-layer--3"
            d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
	H42.401L43.415,98.342z"
            fill="#FFFFFF"
            opacity="0.7"
          />
          <path
            className="deco-layer deco-layer--4"
            d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
            fill="#FFFFFF"
          />
        </svg>
        <div className="pricing-price">
          <span className="pricing-currency">USD$</span>
          {RichText.asText(anual).trim()}
          <span className="pricing-period">/ {translate('annual-label-text')}</span>
        </div>
        <div className="pricing-price-monthtly">
          <span className="pricing-currency">USD$</span>
          {RichText.asText(monthly).trim()}
          <span className="pricing-period">/ {translate('monthly-label-text')}</span>
        </div>
        <h2 className="pricing-title">{RichText.asText(title)}</h2>
      </div>
      <ul className="pricing-feature-list">
        {items.map(({ pricing_item_title }) => {
          const text = RichText.asText(pricing_item_title).trim()
          return (
            <li className="pricing-feature" key={text}>
              {text}
            </li>
          )
        })}
      </ul>
      <a
        href={RichText.asText(general?.mailchimp_urrl)}
        target="_blank"
        rel="noreferrer"
        className="pricing-action"
      >
        {translate('choose-plan-button-text')}
      </a>
      {/* <button */}
      {/*   className="pricing-action" */}
      {/*   onClick={() => { */}
      {/*     history.push({ */}
      {/*       search: qs.encode({ planType: RichText.asText(title) }), */}
      {/*     }) */}
      {/*     showModal() */}
      {/*   }} */}
      {/* > */}
      {/*   {translate('choose-plan-button-text')} */}
      {/* </button> */}
    </div>
  )
}

PlanItem.propTypes = {
  title: PropTypes.array,
  anual: PropTypes.array,
  monthly: PropTypes.array,
  items: PropTypes.array,
  principal: PropTypes.array,
}

export { PlanItem }
