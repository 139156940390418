import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import cn from 'classnames'
import { useFormContext } from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import { IMaskInput } from 'react-imask'

import classes from './styles.module.scss'

const TextField = ({ label, placeholder, type = 'text', name, mask, disabled, className }) => {
  const { register, errors } = useFormContext()

  const error = get(errors, name)

  const masked = React.useMemo(() => {
    switch (mask) {
      case 'identification':
        return '000-0000000-0'
      case 'phone':
        return '({8}0{9}) 000-0000'
      case 'date':
        return '00/00/0000'
      default:
        return undefined
    }
  }, [mask])

  return (
    <Form.Group className={cn('form-group card-label', { [classes.disabled]: disabled })}>
      <Form.Label>{disabled ? '' : label}</Form.Label>
      <IMaskInput
        unmask
        name={name}
        mask={masked}
        className={cn('form-control', className)}
        type={type}
        placeholder={disabled ? placeholder || label : placeholder}
        inputRef={register}
        disabled={disabled}
      />
      <Form.Control.Feedback type="invalid" className={cn({ [classes.visible]: error?.message })}>
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

TextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  mask: PropTypes.oneOf(['identification', 'phone']),
  className: PropTypes.string,
}

export { TextField }
