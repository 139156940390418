import i18n from 'i18next'
// import qs from 'querystring'
import { initReactI18next } from 'react-i18next'
import backend from 'i18next-http-backend'
import { BASE_URL } from '../http'

const getLanguage = () => {
  const locale = window.location.pathname.split('/')[1]
  return locale
}

i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: getLanguage(),
    fallbackLng: 'es',
    saveMissing: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${BASE_URL}/keylists`,
      queryStringParams: { keys: ['translations'] },
      parse: (data) => {
        const { translations = [] } = JSON.parse(data)

        const resources = translations
          .filter(({ language }) => language === getLanguage())
          .reduce((acc, { id, name }) => {
            // if (!acc[language]) {
            //   acc[language] = {
            //     [id.toLowerCase()]: name,
            //   }
            // } else {
            //   acc[language][id.toLowerCase()] = name
            // }
            return { ...acc, [id.toLowerCase()]: name }
          }, {})
        return resources
      },
    },
  })

export default i18n
