import React from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'
import Toast from 'react-bootstrap/Toast'
import { useTranslation } from '@recmed/components/hooks/useTranslation'
import classes from './styles.module.scss'

const AppToast = ({ show, type = 'success', content, onClose }) => {
  const { translate } = useTranslation()

  const title = React.useMemo(() => {
    switch (type) {
      case 'success':
        return translate('success-message-title')
      default:
        return translate('error-message-title')
    }
  }, [type, translate])

  return (
    <Toast
      className={cn(classes.root, classes[type])}
      autohide
      delay={15000}
      onClose={onClose}
      show={show}
    >
      <Toast.Header>
        <strong className="mr-auto">{title}</strong>
      </Toast.Header>
      <Toast.Body>{content}</Toast.Body>
    </Toast>
  )
}

AppToast.propTypes = {
  show: PropTypes.bool,
  content: PropTypes.node,
  type: PropTypes.oneOf(['success', 'info', 'error']),
  onClose: PropTypes.func,
}

export { AppToast }
