import React from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'

const Star = ({ filled, className }) => {
  return <i className={cn('fas fa-star', { filled }, className)} />
}

Star.propTypes = {
  filled: PropTypes.bool,
  className: PropTypes.string,
}

export { Star }
