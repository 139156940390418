import React from 'react'
import PropTypes from 'prop-types'

import Form from 'react-bootstrap/Form'

import get from 'lodash/get'
import cn from 'classnames'
import { useFormContext } from 'react-hook-form'

import { useTranslation } from '@recmed/components/hooks/useTranslation'
import classes from './styles.module.scss'

const Select = ({ label, placeholder, name, disabled, items = [] }) => {
  const { translate } = useTranslation()
  const { register, errors } = useFormContext()

  const error = get(errors, name)

  return (
    <Form.Group className={cn('form-group card-label', { [classes.disabled]: disabled })}>
      <Form.Label>{disabled ? '' : label}</Form.Label>
      <Form.Control
        as="select"
        name={name}
        className="form-control"
        placeholder={disabled ? placeholder || label : placeholder}
        ref={register}
        disabled={disabled}
      >
        <option disabled selected value="">
          {disabled ? label : `-- ${translate('select-option-text')} --`}
        </option>
        {items.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid" className={cn({ [classes.visible]: error?.message })}>
        {error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  items: PropTypes.array,
}

export { Select }
