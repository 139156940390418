import React from 'react'

import Prismic from 'prismic-javascript'
import { prismicClient } from '@recmed/services/external/prismic'

const useFindByDocumentId = ({ documentId }) => {
  const [loading, setLoading] = React.useState(false)
  const [result, setResult] = React.useState(null)

  const handleSetResult = React.useCallback(({ results = [] }) => {
    if (!results.length) return
    const [result] = results
    setResult(result)
  }, [])

  React.useEffect(() => {
    setLoading(true)
    prismicClient
      .query(Prismic.Predicates.at('document.id', documentId))
      .then(handleSetResult)
      .finally(() => setLoading(false))
  }, [handleSetResult, documentId])

  return { loading, result }
}

export { useFindByDocumentId }
