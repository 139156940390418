import React from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'
import { imageShape } from '@recmed/shared/prop-types/prismic'

import classes from './styles.module.scss'

const SectionSearchWrapper = ({ children, background }) => {
  return (
    <>
      <img src={background?.url} className={classes.imageLanding} alt={background?.alt} />
      <section className={cn('section section-search', classes.root)}>{children}</section>
    </>
  )
}

SectionSearchWrapper.propTypes = {
  children: PropTypes.node,
  background: PropTypes.shape(imageShape),
}

export { SectionSearchWrapper }
