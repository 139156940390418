import React from 'react'
import PropTypes from 'prop-types'

import qs from 'querystring'
import { Link } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import { TextField } from '@recmed/components/atoms/TextField'
import { DatePicker } from '@recmed/components/atoms/DatePicker'
import { useTranslation } from '@recmed/components/hooks/useTranslation'
import { useFetch } from '@recmed/components/hooks/useFetch'
import { Loading } from '@recmed/components/atoms/Loading'
import { Select } from '@recmed/components/atoms/Select'
import { useAppContext } from '@recmed/components/hooks/useAppContext'

const CheckoutForm = ({ submit, loading: load }) => {
  const { getPath } = useAppContext()
  const { watch, register } = useFormContext()
  const { translate } = useTranslation()
  const { loading, fetch, data } = useFetch({
    method: 'GET',
    endpoint: `/keylists?${qs.encode({ keys: ['genders', 'insurances', 'documentTypes'] })}`,
  })

  const documentType = watch('documentType', '')
  const insurance = watch('insurance', null)
  const disabled =
    watch('document', '').replace(/-/g, '').substr(0, 11).length <= (documentType === 'CE' ? 10 : 8)

  const termsAndConditions = watch('termsAndConditions')

  React.useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <Card>
      <Card.Body>
        {loading && <Loading className="w-100" />}
        {!loading && (
          <>
            <div className="info-widget">
              <h4 className="card-title">{translate('personal-information-text')}</h4>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <Select
                    name="documentType"
                    label={translate('document-type-text')}
                    items={data?.documentTypes}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    name="document"
                    disabled={['true', '', null, undefined].includes(documentType)}
                    label={translate('identification-card-text')}
                    placeholder={documentType === 'CE' ? '999-9999999-9' : undefined}
                    mask={documentType === 'CE' ? 'identification' : undefined}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField name="name" label={translate('name-label-text')} disabled={disabled} />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    name="lastName"
                    label={translate('last-name-label-text')}
                    disabled={disabled}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <Select
                    name="gender"
                    label={translate('gender-label-text')}
                    disabled={disabled}
                    items={data?.genders}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <DatePicker
                    name="dateOfBirth"
                    disabled={disabled}
                    className="form-control"
                    label={translate('date-of-birth-label-text')}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    mask="phone"
                    name="phone"
                    label={translate('phone-label-text')}
                    disabled={disabled}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <Select
                    name="insurance"
                    label={translate('insurance-label-text')}
                    items={data?.insurances}
                    disabled={disabled}
                  />
                </div>
                {!['', null, undefined, 'PP', 'true'].includes(insurance) && (
                  <div className="col-md-6 col-sm-12">
                    <TextField
                      name="affiliationNo"
                      label={translate('affiliation-number-label-text')}
                      disabled={disabled}
                    />
                  </div>
                )}
                <div className="col-md-6 col-sm-12">
                  <TextField
                    name="email"
                    label={translate('email-label-text')}
                    disabled={disabled}
                  />
                </div>
                <div className="col-md-12 col-sm-12">
                  <TextField
                    name="address"
                    label={translate('address-label-text')}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
            <div className="payment-list">
              <div className="terms-accept">
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="terms_accept"
                    ref={register}
                    name="termsAndConditions"
                  />
                  <label htmlFor="terms_accept">
                    {translate('accept-terms-and-conditions-text')}{' '}
                    <Link to={getPath('/terms')}>{translate('terms-and-conditions-text')}</Link>
                  </label>
                </div>
              </div>
              <div className="submit-section mt-4">
                <Button
                  onClick={() => submit()}
                  type="submit"
                  className="btn btn-primary submit-btn"
                  disabled={!termsAndConditions || load}
                >
                  {translate('confirm-button-text')}
                  {load && <Spinner animation="border" style={{ marginLeft: 8 }} variant="light" />}
                </Button>
              </div>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  )
}

CheckoutForm.propTypes = {
  submit: PropTypes.func,
  loading: PropTypes.bool,
}

export { CheckoutForm }
