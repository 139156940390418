import React from 'react'

import { FormProvider } from 'react-hook-form'
import { Loading } from '@recmed/components/atoms/Loading'
import { BookingSummary } from '@recmed/components/molecules/BookingSummary'

import { CheckoutForm } from '@recmed/components/molecules/CheckoutForm'
import classes from './styles.module.scss'
import { useCheckout } from './useCheckout'

const Checkout = () => {
  const { data, loading, methods, date, doctorId, submit, load } = useCheckout()

  if (loading) return <Loading className={classes.root} />
  return (
    <>
      <FormProvider {...methods}>
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-lg-8">
                <CheckoutForm submit={submit} loading={load}/>
              </div>
              <div className="col-md-5 col-lg-4 theiaStickySidebar">
                <BookingSummary
                  doctorId={doctorId}
                  photo={data?.photo}
                  name={data?.name}
                  rating={data?.stars}
                  location={data?.location?.address}
                  date={date}
                />
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  )
}

export { Checkout }
