import React from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'
import moment from 'moment-timezone'

import classes from './styles.module.scss'

const DateScheduleContent = ({ slots = [], from, onSelect, selected }) => {
  const onSelectSlot = ({ date, available }) => (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (!available) return
    onSelect(date)
  }
  return (
    <div className="schedule-cont">
      <div className="row">
        <div className="col-md-12">
          <div className="time-slot">
            <ul className="clearfix">
              {Array(7)
                .fill(null)
                .map((_, index) => {
                  const current = from.clone().add(index, 'days')
                  const list = slots.filter(({ date }) =>
                    moment(date).isBetween(current, current.clone().endOf('day')),
                  )
                  return (
                    <li key={current.format()}>
                      {list.map(({ date, available }) => {
                        const timing = moment(date)
                        return (
                          <a
                            className={cn('timing', {
                              selected: selected === date,
                              [classes.disabled]: !available,
                            })}
                            href="/"
                            onClick={onSelectSlot({ available, date })}
                            key={date}
                          >
                            <span>{timing.format('hh:mm')}</span> <span>{timing.format('A')}</span>
                          </a>
                        )
                      })}
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

DateScheduleContent.propTypes = {
  slots: PropTypes.array,
  from: PropTypes.object,
  selected: PropTypes.string,
  onSelect: PropTypes.func,
}

export { DateScheduleContent }
