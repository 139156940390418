import React from 'react'
import { Link } from 'react-router-dom'

const Logo = () => {
  return (
    <div className="navbar-header">
      <Link id="mobile_btn" to="/home">
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
      </Link>
      <Link to="/home" className="navbar-brand logo">
        <img src="/img/logo.png" className="img-fluid" alt="Logo" />
      </Link>
    </div>
  )
}

export { Logo }
