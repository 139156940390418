import React from 'react'
import PropTypes from 'prop-types'

import { RichText } from 'prismic-reactjs'
import cn from 'classnames'
import Button from 'react-bootstrap/Button'
import { HeaderText } from '@recmed/components/atoms/HeaderText'
import { imageShape } from '@recmed/shared/prop-types/prismic'
import { useTranslation } from '@recmed/components/hooks/useTranslation'
import classes from './styles.module.scss'

const HomeSplash = ({ className, backgroundImage, title, subtitle }) => {
  const { translate } = useTranslation()
  return (
    <>
      <img className={classes.imageLanding} alt={backgroundImage?.alt} src={backgroundImage?.url} />
      <section className={cn('section text-center', classes.root, className)}>
        <div className="container-fluid">
          <div className={cn('card col-xs-12 col-sm-9 col-md-7 col-lg-5', classes.textWrapper)}>
            <div className="card-body">
              <HeaderText data={title} className={classes.title} />
              <div className={classes.subtitleWrapper}>
                <RichText render={subtitle} className="text-muted" />
              </div>
            </div>
            <div className="card-footer" style={{ background: 'transparent' }}>
              <Button
                className="btn-rounded"
                style={{ padding: '8px 20px' }}
                size="lg"
                onClick={() => {
                  const element = document.getElementById('pricing-section')
                  if (!element) return
                  element.scrollIntoView({ behavior: 'smooth' })
                }}
              >
                {translate('trial-period-button-text')}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

HomeSplash.propTypes = {
  className: PropTypes.string,
  backgroundImage: PropTypes.shape(imageShape),
  title: PropTypes.array,
  subtitle: PropTypes.array,
}

export { HomeSplash }
