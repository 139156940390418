import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment-timezone'
import 'moment/locale/en-gb'
import 'moment/locale/es-do'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import { AppContext } from '@recmed/components/context/AppContext'
import { AppToast } from '@recmed/components/molecules/AppToast'
import { Loading } from '@recmed/components/atoms/Loading'
import { useFindByDocumentId } from '@recmed/components/hooks/useFindByDocumentId'
import { RichText } from 'prismic-reactjs'

const getLanguage = () => {
  const locale = window.location.pathname.split('/')[1]
  switch (locale) {
    case 'es':
      moment.locale('es-DO')
      registerLocale('es', es)
      setDefaultLocale('es')
      break
    default:
      moment.locale('en-GB')
  }
  return locale
}

const toastInitialState = {
  show: false,
  type: 'success',
  content: null,
}

const AppContextProvider = ({ children }) => {
  const [toast, setToast] = React.useState(toastInitialState)
  const language = React.useMemo(() => getLanguage(), [])

  const displayToast = React.useCallback(({ type = 'info', content = '' }) => {
    setToast({ ...toastInitialState, type, content, show: true })
  }, [])

  const { loading, result } = useFindByDocumentId({ documentId: 'XyA-BxEAACMApgnE' })

  React.useEffect(() => {
    if (!result?.data?.head_scripts_section?.length) return
    const {
      data: { head_scripts_section },
    } = result

    head_scripts_section.forEach(({ script: s = [], script_id = [], source = [] }, key) => {
      const script = document.createElement('script')
      script.id = RichText.asText(script_id) || key
      script.text = RichText.asText(s)
      const src = RichText.asText(source)
      if (src) {
        script.src = src
        script.async = true
      }
      document.head.appendChild(script)
    })
  }, [result])

  React.useEffect(() => {
    if (!result?.data?.scripts_section?.length) return

    const {
      data: { scripts_section },
    } = result

    scripts_section.forEach(({ script: s, script_id = [], source = [] }, key) => {
      const script = document.createElement('script')
      script.id = RichText.asText(script_id) || key
      script.text = RichText.asText(s)
      const src = RichText.asText(source)
      if (src) {
        script.src = src
        script.async = true
      }
      document.body.appendChild(script)
    })
  }, [result])

  return (
    <AppContext.Provider value={{ language, displayToast, general: result?.data }}>
      <React.Suspense fallback={() => <Loading style={{ height: '100vh', width: '100%' }} />}>
        <AppToast
          show={toast.show}
          content={toast.content}
          type={toast.type}
          onClose={() => setToast(toastInitialState)}
        />
        {loading ? <Loading style={{ height: '100vh', width: '100%' }} /> : children}
      </React.Suspense>
    </AppContext.Provider>
  )
}

AppContextProvider.propTypes = {
  children: PropTypes.node,
}

export { AppContextProvider }
