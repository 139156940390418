import React from 'react'

import qs from 'querystring'
import { useLocation } from 'react-router-dom'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { BreadcrumbBar } from '@recmed/components/atoms/BreadcrumbBar'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { DoctorFilter } from '@recmed/components/organisisms/DoctorFilter'
import { useFetch } from '@recmed/components/hooks/useFetch'
import { Loading } from '@recmed/components/atoms/Loading'
import { SearchList } from '@recmed/components/organisisms/SearchList'
import { useAppContext } from '@recmed/components/hooks/useAppContext'
import { useTranslation } from '@recmed/components/hooks/useTranslation'
import { SearchContent } from './SearchContent'

import classes from './styles.module.scss'

const Search = () => {
  const { getPath } = useAppContext()
  const { translate } = useTranslation()
  const { search } = useLocation()
  const { data, fetch, loading } = useFetch({
    endpoint: '/doctors/find',
    method: 'POST',
  })

  React.useEffect(() => {
    fetch(qs.decode(search.substr(1)))
  }, [fetch, search])

  return (
    <>
      <BreadcrumbBar>
        <Breadcrumb>
          <Link component={Breadcrumb.Item} to={getPath('/doctors')}>
            {translate('doctor-list')}
          </Link>
          <Link component={Breadcrumb.Item} to={getPath('/search')}>
            {translate('doctor-search')}
          </Link>
        </Breadcrumb>
      </BreadcrumbBar>
      <SearchContent>
        <div className="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
          <DoctorFilter />
        </div>
        {loading && <Loading className={cn('col-md-12 col-lg-8 col-xl-9', classes.root)} />}
        {!loading && (
          <div className={cn('col-md-12 col-lg-8 col-xl-9', classes.root)}>
            <SearchList items={data?.doctors} />
          </div>
        )}
      </SearchContent>
    </>
  )
}

export { Search }
