import React from 'react'
import PropTypes from 'prop-types'

import { RichText } from 'prismic-reactjs'
import Slider from 'react-slick'
import { HeaderText } from '@recmed/components/atoms/HeaderText'

const HomeFeature = ({ title, subtitle, items }) => {
  const [feature, setFeature] = React.useState(() => {
    return items[0]?.feature_item_description
  })

  return (
    <>
      <section className="section section-features">
        <div className="container-fluid">
          <div className="sect">
            <div className="section-header text-center">
              <HeaderText data={title} />
              <RichText render={subtitle} />
              <RichText render={feature} />
            </div>

            <div className="row justify-content-center">
              <div className="col-md-9">
                <Slider
                  dots
                  dotsClass="slick-dots"
                  arrows={false}
                  infinite
                  autoplay
                  beforeChange={(_, next) => {
                    // setImage(items[next].feature_image)
                    setFeature(items[next].feature_item_description)
                  }}
                  slidesToShow={items.length >= 6 ? 6 : items.length}
                  responsive={[
                    {
                      breakpoint: 1380,
                      settings: {
                        slidesToShow: 5,
                      },
                    },
                    {
                      breakpoint: 1280,
                      settings: {
                        slidesToShow: 4,
                      },
                    },
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 3,
                      },
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                      },
                    },
                  ]}
                >
                  {items.map(
                    ({
                      feature_item_title: titleItem,
                      feature_image: imageItem,
                      feature_item_description,
                    }) => (
                      <div
                        className="feature-item text-center"
                        key={`item-feature-${titleItem[0]?.text}`}
                        style={{ maxWidth: 180 }}
                        onClick={() => {
                          // setImage(imageItem)
                          setFeature(feature_item_description)
                        }}
                      >
                        <img src={imageItem?.url} className="img-fluid" alt={imageItem?.alt} />
                        <RichText render={titleItem} />
                      </div>
                    ),
                  )}
                </Slider>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
        {/* </div> */}
      </section>

    </>
  )
}

HomeFeature.propTypes = {
  title: PropTypes.array,
  subtitle: PropTypes.array,
  items: PropTypes.array,
}

export { HomeFeature }
