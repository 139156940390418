import React from 'react'
import PropTypes from 'prop-types'
import { NavLink, Link } from 'react-router-dom'

const NavbarMenuItem = ({ title, children, to, hasSubMenu }) => {
  if (hasSubMenu) {
    return (
      <li className="has-submenu" activeClassName="active" >
        <a>
          {title}
          <i className="fas fa-chevron-down" />
        </a>
        <ul className="submenu">{children}</ul>
      </li>
    )
  }
  return (
    <NavLink activeClassName="active" component="li" to={to}>
      <Link to={to}>{title || children}</Link>
    </NavLink>
  )
}

NavbarMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  to: PropTypes.string.isRequired,
  hasSubMenu: PropTypes.bool,
}

export { NavbarMenuItem }
