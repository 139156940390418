import React from 'react'

import moment from 'moment-timezone'
import { BreadcrumbBar } from '@recmed/components/atoms/BreadcrumbBar'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link, useParams } from 'react-router-dom'
import { useAppContext } from '@recmed/components/hooks/useAppContext'
import { useTranslation } from '@recmed/components/hooks/useTranslation'
import { useFetch } from '@recmed/components/hooks/useFetch'
import { Loading } from '@recmed/components/atoms/Loading'

const BookingSuccess = () => {
  const { doctorId, organizationId, date: selected } = useParams()
  const { getPath } = useAppContext()
  const { translate } = useTranslation()
  const { loading, data, fetch } = useFetch({ method: 'POST', endpoint: '/doctors/slots' })

  const date = React.useMemo(() => moment(selected), [selected])

  React.useEffect(() => {
    fetch({
      from: date.format(),
      to: date.format(),
      doctorId,
      organizationId,
    })
  }, [date, doctorId, organizationId, fetch])

  return (
    <>
      <BreadcrumbBar>
        <Breadcrumb>
          <Link component={Breadcrumb.Item} to={getPath('/doctors')}>
            {translate('doctor-list')}
          </Link>
          <Link component={Breadcrumb.Item} to={getPath('/search')}>
            {translate('doctor-search')}
          </Link>
        </Breadcrumb>
        <h2 className="breadcrumb-title">{translate('booking-confirmation-header-text')}</h2>
      </BreadcrumbBar>
      <div className="content success-page-cont" style={{ minHeight: '50vh' }}>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              {loading && <Loading />}
              {!loading && (
                <div className="card success-card">
                  <div className="card-body">
                    <div className="success-cont">
                      <i className="fas fa-check" />
                      <h3>{translate('appointment-booked-succesfully-text')}</h3>
                      <p>
                        {translate('appointment-booked-with-part-1-text')}{' '}
                        <strong>{data?.name}</strong>
                        <br />
                        {translate('at-text')}{' '}
                        <strong>{date.format('DD MMMM YYYY hh:mm A')}</strong>
                      </p>
                      <Link to={getPath('/search')} className="btn btn-primary view-inv-btn">
                        {translate('go-to-search-text')}
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { BookingSuccess }
