import React from 'react'

import cn from 'classnames'
import { RichText } from 'prismic-reactjs'
import { useTranslation } from '@recmed/components/hooks/useTranslation'
import { useAppContext } from '@recmed/components/hooks/useAppContext'
import { Link } from 'react-router-dom'
import classes from './styles.module.scss'

const Footer = ({
  message = [],
  instagram = [],
  facebook = [],
  phone = [],
  linkedin = [],
  whatsapp = [],
  whatsappMessage = [],
}) => {
  const { getPath } = useAppContext()
  const { translate } = useTranslation()
  return (
    <footer className={cn('footer', classes.footer)}>
      <div className="footer-top">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget footer-about">
                <div className={cn('recmed-font', classes.font)}>RECMED</div>
                <div className="footer-about-content">
                  <RichText render={message} />
                  <div className="social-icon">
                    <ul>
                      <li>
                        <a
                          href={RichText.asText(facebook)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a
                          href={RichText.asText(linkedin)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </li>
                      <li>
                        <a
                          href={RichText.asText(instagram)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://wa.me/${RichText.asText(whatsapp)}?text=${RichText.asText(
                            whatsappMessage,
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-whatsapp" />
                        </a>
                      </li>
                      <li>
                        <a href={`tel:${RichText.asText(phone)}`}>
                          <i className="fab fa fa-phone" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container-fluid">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="copyright-menu">
                  <ul className="policy-menu">
                    <li>
                      <Link to={getPath('/terms')}>{translate('terms-and-conditions-text')}</Link>
                    </li>
                    {/* <li> */}
                    {/*   <a href="privacy-policy.html">{translate('policy-text')}</a> */}
                    {/* </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="copyright-text">
                  <p className="mb-0">
                    &copy; {new Date().getFullYear()} Recmed.{' '}
                    {translate('all-rights-reserved-text')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export { Footer }
