import PropTypes from 'prop-types'

const imageShape = {
  alt: PropTypes.string,
  url: PropTypes.string,
  dimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
}

export { imageShape }
