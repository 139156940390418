import React from 'react'

import { Switch, Route, Redirect, useHistory } from 'react-router-dom'

import { Header } from '@recmed/components/atoms/Header'
import { Logo } from '@recmed/components/atoms/Logo'

import { NavbarWrapper } from '@recmed/components/molecules/NavbarWrapper'
import { NavbarMenu } from '@recmed/components/atoms/NavbarMenu'
import { NavbarMenuItem } from '@recmed/components/atoms/NavbarMenuItem'
import { Landing } from '@recmed/pages/Landing'
import { Dashboard } from '@recmed/pages/Dashboard'
import { Search } from '@recmed/pages/Search'
import { useAppContext } from '@recmed/components/hooks/useAppContext'
import { Booking } from '@recmed/pages/Booking'
import { Checkout } from '@recmed/pages/Checkout'
import { BookingSuccess } from '@recmed/pages/BookingSuccess'
import { useTranslation } from '@recmed/components/hooks/useTranslation'
import { Footer } from '@recmed/components/atoms/Footer'
import { TermsAndConditions } from '@recmed/components/molecules/TermsAndConditions'

// const validLanguages = ['es', 'en']
const validLanguages = ['es']

function App() {
  const history = useHistory()
  const { getPath, general } = useAppContext()
  const { translate } = useTranslation()

  React.useEffect(() => {
    const defaultPath = window.location.pathname.split('/')[1]
    if (validLanguages.includes(defaultPath)) return
    history.replace(`/es/home`)
    window.location.reload()
  }, [history])

  return (
    <div className="main-wrapper">
      <Header>
        <Logo />
        <NavbarWrapper phone={general?.phone}>
          <NavbarMenu>
            <NavbarMenuItem to={getPath('/home')}>{translate('home-menu-item')}</NavbarMenuItem>
            <NavbarMenuItem to={getPath('/doctors')}>
              {translate('book-appointment-menu-item')}
            </NavbarMenuItem>
            <NavbarMenuItem to={getPath('/search')}>
              {translate('doctor-list-menu-item')}
            </NavbarMenuItem>
          </NavbarMenu>
        </NavbarWrapper>
      </Header>
      <Switch>
        <Route path="/:lang/home">
          <Landing />
        </Route>
        <Route path="/:lang/doctors">
          <Dashboard />
        </Route>
        <Route path="/:lang/search">
          <Search />
        </Route>
        <Route path="/:lang/booking/success/:doctorId/:organizationId/:date">
          <BookingSuccess />
        </Route>
        <Route path="/:lang/booking/:doctorId/:organizationId/:date">
          <Checkout />
        </Route>
        <Route path="/:lang/booking/:doctorId/:organizationId">
          <Booking />
        </Route>
        <Route path="/:lang/terms">
          <TermsAndConditions />
        </Route>
        <Redirect to={getPath('/home')} />
      </Switch>
      <Footer
        phone={general?.phone}
        message={general?.footer_message}
        instagram={general?.instagram}
        facebook={general?.facebook}
        linkedin={general?.linkedin}
        whatsapp={general?.whatsapp}
        whatsappMessage={general?.whatsapp_prefilled_message}
      />
    </div>
  )
}

export default App
