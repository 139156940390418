import React from 'react'
import PropTypes from 'prop-types'

import { RichText } from 'prismic-reactjs'
import Slider from 'react-slick'
import { HeaderText } from '@recmed/components/atoms/HeaderText'

const HomeSpeciality = ({ title, subtitle, items = [] }) => {
  return (
    <section className="section section-doctor">
      <div className="container-fluid">
        <div className="section-header text-center">
          <HeaderText data={title} />
          <p className="sub-title">{RichText.asText(subtitle)}</p>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-9">
            <Slider
              dots
              dotsClass="slick-dots"
              arrows={false}
              infinite
              autoplay
              slidesToShow={items.length >= 6 ? 6 : items.length}
              responsive={[
  {
                  breakpoint: 1380,
                  settings: {
                    slidesToShow: 5,
                  },
                },
  {
                  breakpoint: 1280,
                  settings: {
                    slidesToShow: 4,
                  },
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ]}
            >
              {items.map(({ speciality__image: image, speciality_image_descrirption: text }) => (
                <div className="speicality-item text-center" key={image?.url}>
                  <div className="speicality-img">
                    <img src={image?.url} className="img-fluid" alt={image?.alt} />
                    <span>
                      <i className="fa fa-circle" aria-hidden="true" />
                    </span>
                  </div>
                  <p>{RichText.asText(text)}</p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

HomeSpeciality.propTypes = {
  title: PropTypes.array,
  subtitle: PropTypes.array,
  items: PropTypes.array,
}

export { HomeSpeciality }
