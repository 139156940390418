import React from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'
import Button from 'react-bootstrap/Button'
import { RichText } from 'prismic-reactjs'
import { SectionSearchWrapper } from '@recmed/components/atoms/SectionSearch'
import { HeaderText } from '@recmed/components/atoms/HeaderText'
import { imageShape } from '@recmed/shared/prop-types/prismic'

import { useTranslation } from '@recmed/components/hooks/useTranslation'
import classes from './styles.module.scss'

const DashboardSearch = ({ image, title, subtitle, value, onChange, onSubmit }) => {
  const { translate } = useTranslation()
  return (
    <SectionSearchWrapper background={image}>
      <div className="container-fluid">
        <div className={cn('card banner-wrapper', classes.textWrapper)}>
          <div className="banner-header text-center">
            <HeaderText data={title} />
            <RichText render={subtitle} />
          </div>
          <div className="row search-box justify-content-center">
            <div className="form-group search-info">
              <input
                type="text"
                value={value}
                className="form-control"
                placeholder={translate('search-filters')}
                onChange={({ target: { value } }) => {
                  onChange(value)
                }}
              />
            </div>
            <Button className="btn btn- search-btn" onClick={onSubmit}>
              <i className="fas fa-search" /> <span>{translate('search-button-text')}</span>
            </Button>
          </div>
        </div>
      </div>
    </SectionSearchWrapper>
  )
}

DashboardSearch.propTypes = {
  image: PropTypes.shape(imageShape),
  title: PropTypes.array,
  subtitle: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
}

export { DashboardSearch }
