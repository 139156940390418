import React from 'react'
import PropTypes from 'prop-types'

import { FormProvider } from 'react-hook-form'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import { Select } from '@recmed/components/atoms/Select'
import { TextField } from '@recmed/components/atoms/TextField'
import { usePlanModal } from './useChoosePlanModal'

const ChoosePlanModal = ({ show, onClose, plans = [] }) => {
  const {
    commercialType,
    items,
    prices,
    translate,
    planType,
    methods,
    handleSubmit,
    loading,
  } = usePlanModal({ plans, onClose })

  return (
    <Modal show={show} backdrop="static" onHide={onClose} keyboard={false} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{translate('choose-plan-modal-text')}</Modal.Title>
      </Modal.Header>
      <FormProvider {...methods}>
        <Modal.Body className="row">
          <div className="col-md-6 col-sm-12">
            <TextField name="name" label={translate('name-label-text')} />
          </div>
          <div className="col-md-6 col-sm-12">
            <TextField name="lastName" label={translate('last-name-label-text')} />
          </div>
          <div className="col-md-6 col-sm-12">
            <TextField mask="phone" name="phone" label={translate('phone-label-text')} />
          </div>
          <div className="col-md-6 col-sm-12">
            <TextField name="email" label={translate('email-label-text')} />
          </div>
          <div className="col-md-6 col-sm-12">
            <Select name="planType" label={translate('plan-type-text')} items={items} />
          </div>
          <div className="col-md-6 col-sm-12">
            <Select
              name="planFee"
              label={translate('plan-fee-text')}
              items={prices}
              disabled={!planType}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <Select
              name="commercialType"
              label={translate('commercial-type-text')}
              items={[
                { id: 'DR', name: translate('doctor-commercial-type-text') },
                { id: 'OFFICE', name: translate('consulting-room-commercial-type-text') },
              ]}
            />
          </div>
          {commercialType === 'DR' && (
            <div className="col-md-6 col-sm-12">
              <TextField name="speciality" label={translate('speciality-label-text')} />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit} disabled={loading} size="lg">
            {translate('submit-button-text')}
            {loading && <Spinner animation="border" style={{ marginLeft: 8 }} variant="light" />}
          </Button>
        </Modal.Footer>
      </FormProvider>
    </Modal>
  )
}

ChoosePlanModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  plans: PropTypes.array,
}

export { ChoosePlanModal }
