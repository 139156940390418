import React from 'react'

const AppContext = React.createContext({
  general: {},
  language: window.navigator.language.substr(0, 2),
  displayToast: ({ content = '', title = '', type = 'success' }) =>
    console.info(content, title, type),
})

export { AppContext }
